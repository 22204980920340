import React, { useEffect, useState } from 'react';
import './App.css';

const shared_key = 'mvbOnQ_WjXl6Oh5v_apQb88ftYr2EexlGJo7MJkqHnN61LNMFl_RNVIOl7UFVErBFScd2HhS5L3Z4Ew979brpw';

function App() {
  const jwt = require('jsonwebtoken');
  const [generatedJwt, setGeneratedJwt] = useState<string>('');

  function generateJwt() {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 1);
    const exp = Math.floor(expirationDate.getTime() / 1000);

    const params = new URLSearchParams(window.location.search);
    const name = params.get('name');
    const email = params.get('email');
    const external_id = params.get('external_id');

    console.log(name)
    console.log(email)
    console.log(external_id)

    const payload = { external_id, name, email, exp, scope: "user" };

    const header = {
      alg: "HS256",
      typ: "JWT",
      kid: "app_6638d13da52036ad4c623ca8"
    }

    return jwt.sign(payload, shared_key, { header: header })
  }

  useEffect(() => {
    //Função responsável por trazer o chat expandido
    (window as any).zE("messenger", 'open');

    //Gera o jwt
    const jwtToken = generateJwt();
    setGeneratedJwt(jwtToken);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    if (generatedJwt) {
      //Associa o valor do jwt gerado
      (window as any).zE("messenger", "loginUser", function (callback: (jwtToken: string) => void) {
        callback(generatedJwt);
      });

      //Campos personalizados
      const params = new URLSearchParams(window.location.search);
      const telefone = params.get('telefone');
      const empresa = params.get('empresa');
      const versao = params.get('versao');

      const custom_fields: any = [];

      if (telefone)
        custom_fields.push({ id: 25566101782427, value: telefone });

      if (empresa)
        custom_fields.push({ id: 25566095761435, value: empresa });

      if (versao)
        custom_fields.push({ id: 25557134588059, value: versao });

      (window as any).zE("messenger:set", "conversationFields", custom_fields);
    }

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [generatedJwt]);

  return <div className="App"></div>;
}

export default App;
